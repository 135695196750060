<template>
  <div class="box-body">
    <div class="panel panel-default">
      <div class="form-group col-12">
        <transition name="fade">
          <vue-dropzone v-if="onProgressLoadFile === false"
                        id="dropzone"
                        ref="client_files"
                        :duplicateCheck="true"
                        :options="dropzoneOptions"
                        v-on:vdropzone-file-added="sendFile"
                        v-on:vdropzone-success="getFiles"
                        v-bind:style="onProgressLoadFile === true ? 'opacity: 0.5;' : 'opacity: 1;'">
          </vue-dropzone>
          <loading-processing v-else :size="60"></loading-processing>
        </transition>
      </div>

      <transition name="fade">
        <div class="row" v-if="onProgress === false">
          <div class="col-lg-6 col-md-12" v-for="item in client_files">
            <div class="image-box">
              <img v-if="dropzoneOptions.img_extensions.includes(item.file_url.slice(item.file_url.lastIndexOf('.')))"
                   :src="item.is_cloud_storage === 1 ? item.file_url : item.storage_app_url + item.file_url"
                   class="img-fluid p-3" :alt="item.description">
              <div v-else class="full-height p-3">
                <div class="p-2 bg-gray-light">
                  <h5 class="mb-3 font-scale-2" style="word-break: break-all;">
                    {{ item.file_url.slice(item.file_url.lastIndexOf('/')).substring(1) }}

                    <br/>

                    <small class="font-small-xs">
                      {{ item.created_date }}
                    </small>

                    <br/>
                    <br/>
                  </h5>
                </div>
              </div>
              <button @click="removeFile(item.id)"
                      class="btn" title="Удалить файл" type="button">
                <i class="fa fa-trash"></i>
              </button>
              <a :href="item.is_cloud_storage === 1 ? item.file_url : item.storage_app_url + item.file_url"
                 target="_blank" class="btn" title="Открыть"
                 style="left: 82%; right: 0; opacity: 0.85;">
                <i class="fas fa-external-link-square-alt"></i>
              </a>
            </div>
          </div>
          <div v-if="client_files.length === 0" class="text-center text-muted mt-5 mb-5">
            Нет элементов для отображения
          </div>
        </div>
        <loading-processing v-else></loading-processing>
      </transition>
    </div>
  </div>

</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import dropzone_config from './../../../assets/data/dropzone-config.json';

export default {
  name: 'client-files',

  components: {
    vueDropzone: vue2Dropzone,
  },

  props: {
    clientId: {
      type: [String, Number],
      required: true,
    },
  },

  data: () => ({
    client_files: [],

    dropzoneOptions: dropzone_config,

    onProgress: true,
    onProgressLoadFile: false,
  }),

  async created() {
    try {
      this.dropzoneOptions.url = '/client/' + this.clientId + '/files/load';

      await this.getFiles();
    } catch (error) {
      errorHandler(error);
    }
  },

  methods: {
    async getFiles() {
      try {
        const client_files_response = await API.apiClient.get('/client/' + this.clientId + '/files');

        this.client_files = client_files_response.data;
      } catch (error) {
        errorHandler(error);
      }

      this.onProgress = false;
    },

    async sendFile(file) {
      try {
        this.onProgressLoadFile = true;
        this.$refs.client_files.disable();

        const form_data = new FormData();
        form_data.append('client_id', this.clientId.toString());
        form_data.append('file', file);

        await API.apiClient.post(this.dropzoneOptions.url, form_data).then(() => {
          this.onProgressLoadFile = false;
          this.getFiles();
          showSuccess();
        });

        this.$refs.client_files.removeFile(file);
      } catch (error) {
        this.onProgressLoadFile = false;
        errorHandler(error);
      }

      this.$refs.client_files.enable();
    },

    removeFile(file_id) {
      this.onProgress = true;

      API.apiClient.delete('/client/' + this.clientId + '/files', {
        data: {
          file_id: file_id,
        }
      }).then(() => {
        this.getFiles();
        showSuccess();
      }).catch((error) => {
        errorHandler(error);
      });
    },
  },
}
</script>

<style scoped>
.image-box {
  position: relative;
}

.image-box img {
  width: 100%;
  height: auto;
}

.image-box .btn {
  position: absolute;
  bottom: 5%;
  right: 70%;
  transform: translate(-45%, -55%);
  -ms-transform: translate(-45%, -55%);
  background-color: #424656;
  color: white;
  font-size: 14px;
  border: none;
  cursor: pointer;
  border-radius: 2px;
  opacity: 0.65;
  max-width: 45px;
}

.image-box .btn:hover {
  background-color: black;
}
</style>